html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

b,
strong {
  font-weight: 700;
}
html {
  font-family: "Nunito Sans", "Helvetica", "Arial", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background: linear-gradient(#afacdc 0%,#ffffff 45%);
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  #root {
    overflow-x: hidden;
  }
}
@media (max-width: 1024px) {
  #root {
    overflow-x: hidden;
  }
}
// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }
}

// #root {
//   overflow: hidden;
// }

.weather-details-enter {
  max-height: 0;
  margin-left:70px;
  background:linear-gradient(#afacdc 11%, #ffffff 114%);
}
.weather-details-enter-active {
  max-height: 600px;
  transition: max-height 300ms;
}
.weather-details-exit {
  max-height: 600px;
}
.weather-details-exit-active {
  max-height: 0;
  transition: max-height 300ms;
}

.page-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.loading-img {
  margin: auto;
}

::-ms-clear {
  display: none;
  -webkit-appearance: none;
  height: 0;
  width: 0;
}

::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
  height: 0;
  width: 0;
}
.css-ja5taz-MuiTooltip-tooltip{
  padding: 5px 9px !important;
  font-size: 14px !important;
  font-family: "Roboto" !important;
  @media (max-width: 1080px) {
    font-size: 13px !important;
  };
  @media (max-width:800px) {
    font-size: 12px !important;
};
}