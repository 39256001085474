$primary-50: #f0f6e7 !default;
$primary-100: #d9e9c4 !default;
$primary-200: #bfda9c !default;
$primary-300: #a5cb74 !default;
$primary-400: #92bf57 !default;
$primary-500: #7fb439 !default;
$primary-600: #77ad33 !default;
$primary-700: #6ca42c !default;
$primary-800: #629c24 !default;
$primary-900: #4f8c17 !default;
$primary: $primary-500 !default;

$secondary-50: #eae8f3 !default;
$secondary-100: #c9c7e1 !default;
$secondary-200: #a6a1cd !default;
$secondary-300: #827bb9 !default;
$secondary-400: #675faa !default;
$secondary-500: #4c439b !default;
$secondary-600: #453d93 !default;
$secondary-700: #3c3489 !default;
$secondary-800: #332c7f !default;
$secondary-900: #241e6d !default;
$secondary: $secondary-500 !default;

$error: #ff8a00 !default;

$black: #000;
$white: #fff;

// New colors
$gray-color: #999999 !default;
$black-color: rgba(0,0,0,0.87) !default;

$black: #000000;
$rgb-black: "0,0,0" !default;
$white: #ffffff;
$rgb-white: "255,255,255" !default;

$surface-card: #f0f0f3;
$outline-default: #333333;

$background: #f5f5f5;

$link-color: $secondary !default;
$link-decoration: none !default;
$link-hover-color: $secondary-700 !default;
$link-hover-decoration: underline !default;
