@import "core/variables";
@import "core/misc";

.service-banner{
  height: 235px;
  //height: auto;
  //background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  cursor: pointer;
  margin-bottom: 20px;
  background-repeat: round;
  //box-shadow: 10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c;
  .image-banner{
    width: 100%;
    height: 100%;
    //border-radius: 18px;
  }
  @media (max-width: 1024px) {
    //height: 220px;
    //height: auto;
    margin-top: 32px;
    height: 225px;
  }
}
.report-banner{
  height: 235px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  //border-radius: 18px;
  //box-shadow: 10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c;
  box-shadow: none;
  background-repeat: round;
  .image-banner{
    width: 100%;
    height: 100%;
    //border-radius: 18px;
  }
  @media (max-width: 1024px) {
    height: 225px;
  }
}
.service-banner-mobile{
  height: 80px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  //border-radius: 18px;
  cursor: pointer;
  margin: 20px 0px;
  //box-shadow: 10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c;
  .image-banner-mobile{
    width: 100%;
    height: 100%;
    border-radius: 18px;
  }
}
#highcharts-element{
  .highcharts-yaxis{
    color: #000000 !important;
    font-size: 18px !important;
    font-style: normal;
    text-align: center;
    font-family: Roboto;
    font-weight: 400;
    line-height: 24px;
  }
}
.ul-description-weather-report{
  margin-top: 6px !important;
}